<template>
  <div class="main-content">
    <div @click="goOff" class="back">
      返回
    </div>
    <div class="iframe-container">
      <iframe
        src="https://www.mob.com/about/policy"
        scrolling="auto"
        frameborder="0"
        class="iframe"
      ></iframe>
    </div>
  </div>
</template>
<script>
export default {
  name: "sdk",
  methods: {
    goOff() {
      this.$router.go(-1);
    }
  }
};
</script>
<style scoped>
.main-content {
  width: 100%;
  height: 100%;
  padding: 0 0.1rem;
  background: rgb(255, 255, 255);
}
.iframe-container {
  width: 100%;
  height: 100%;
}
.iframe {
  width: 100%;
  height: 100%;
}
.back {
  padding-top: 20px;
}
</style>
